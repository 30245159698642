import React from 'react';
import { setOasPensionYou } from "../../../../store/actions/SourceOfIncomeActions";
import { connect } from "react-redux";
import InputStep from "../InputStep";

const OasPensionAmountYou = ( props ) => {

    const onChange = value => {
        props.setOasPensionYou(value)
    };

    return (
        <InputStep
            label={'Monthly OAS'}
            onChange={onChange}
            max={800}
            total={props.oasPensionTotal}
        />
    )
};

function mapStateToProps(state) {
    return {
        oasPensionTotal: state.sourceOfIncome.you.oasPension.val
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setOasPensionYou: (val) => dispatch(setOasPensionYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OasPensionAmountYou);