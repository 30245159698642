import React, { useCallback, useEffect, useState } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import OasPensionAmountYou from "../../../components/ContentRow/Step4/OasPensionAmount/OasPensionAmountYou";
import OasPensionAmountSpouse from "../../../components/ContentRow/Step4/OasPensionAmount/OasPensionAmountSpouse";
import { fourthStepCompleted } from "../../../store/actions/SourceOfIncomeActions";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const OasPension = ( props ) => {
    const titles = {
        layoutTitle: 'OAS PENSION',
        sectionTitle: 'The maximum OAS Pension benefit is $613.53 per month, starting at age 65. How much will you receive?',
    };

    const title = "Amount in OAS Pension benefit you will receive";

    const [next, setNext] = useState(retirementCalcLinks.sourceOfIncomeSummary);

    const nextLink = useCallback(() => {
        if(props.sourceOfIncomeYou.employerPension.exist || props.sourceOfIncomeSpouse.employerPension.exist){
            return retirementCalcLinks.employerPensionAmount;
        }else if(props.sourceOfIncomeYou.other.exist || props.sourceOfIncomeSpouse.other.exist){
            return retirementCalcLinks.otherAmount;
        }else {
            return retirementCalcLinks.sourceOfIncomeSummary
        }
    }, [props]);


    useEffect(() => {
        props.fourthStepCompleted(false);
        setNext(nextLink())
    }, [props, nextLink]);

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep4={(3/6)*100}
            active={'step4'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent='oasPension'
                sectionTitle={titles.sectionTitle}
            >

                {
                    props.sourceOfIncomeYou.oasPension.exist &&
                    <InnerLayoutStepContent type={'you'}>
                        <OasPensionAmountYou/>
                    </InnerLayoutStepContent>
                }

                {
                    props.isSpouse && props.sourceOfIncomeSpouse.oasPension.exist &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <OasPensionAmountSpouse/>
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        sourceOfIncomeYou: state.sourceOfIncome.you,
        sourceOfIncomeSpouse: state.sourceOfIncome.spouse,
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fourthStepCompleted: (val) => dispatch(fourthStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OasPension);