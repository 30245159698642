import React from 'react';
import { setOasPensionSpouse } from "../../../../store/actions/SourceOfIncomeActions";
import { connect } from "react-redux";
import InputStep from "../InputStep";

const OasPensionAmountSpouse = ( props ) => {

    const onChange = value => {
        props.setOasPensionSpouse(value)
    };

    return (
        <InputStep
            label={'Monthly OAS'}
            onChange={onChange}
            max={800}
            total={props.oasPensionTotal}
        />
    )
};

function mapStateToProps(state) {
    return {
        oasPensionTotal: state.sourceOfIncome.spouse.oasPension.val
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setOasPensionSpouse: (val) => dispatch(setOasPensionSpouse(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OasPensionAmountSpouse);